export default [
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/pages/home/logout.vue'),
    },
    {
        path: '/setupuseraccount',
        name: 'setupuseraccount',
        component: () => import('@/views/pages/home/setupsubdomain.vue'),
    },
    {
        path: '/projects',
        name: 'projects',
        component: () => import('@/views/pages/home/projects.vue'),
        //meta: {
        //    pageTitle: 'Projects',
        //    breadcrumb: [
        //        {
        //            text: 'Projects',
        //            active:true
        //        },
        //    ],
        //},        
    },
    {
        path: '/manageusers',
        name: 'manageusers',
        component: () => import('@/views/pages/home/manageusers.vue'),
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/views/pages/home/contacts.vue'),
    },
    {
        path: '/sendemails',
        name: 'sendemails',
        component: () => import('@/views/pages/home/sendemails.vue'),
    },
    {
        path: '/addeditemail/:id',
        name: 'addeditemail',
        component: () => import('@/views/pages/home/addeditemail.vue'),
        meta: {
            navActiveLink: 'sendemails',
        },
    },
    {
        path: '/smtpconfigurations',
        name: 'smtpconfigurations',
        component: () => import('@/views/pages/home/smtpconfigurations.vue'),
    },
    {
        path: '/paymentmethods',
        name: 'paymentmethods',
        component: () => import('@/views/pages/home/paymentmethods.vue'),
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/pages/home/Orders.vue'),
    },
    {
        path: 'http://support.landingly.com',
        name: 'support',
        beforeEnter() { window.open('http://support.landingly.com', '_blank') }
        /*component: () => import('@/views/pages/home/support.vue'),*/

    },
    {
        path: 'https://app.landingly.com/Landingly_Webinar_Bonus_Leadmagnet.zip',
        name: 'bonus',
        beforeEnter() { window.open('https://app.landingly.com/Landingly_Webinar_Bonus_Leadmagnet.zip', '_blank') }
        /*component: () => import('@/views/pages/home/support.vue'),*/

    },
    {
        path: '/training',
        name: 'training',
        component: () => import('@/views/pages/home/training.vue'),
    },
    {
        path: '/projectdetail/:userfunnelid',
        name: 'project-detail',
        component: () => import('@/views/pages/home/projectdetail.vue'),
        meta: {
            navActiveLink: 'projects',
        },
        //meta: {
        //    pageTitle: 'Project Detail',
        //    breadcrumb: [
        //        {
        //            text: 'Projects'
        //        },
        //        {
        //            text: 'Project Detail',
        //            active: true
        //        },
        //    ],
        //},
    },
    {
        path: '/verifystripe',
        name: 'verifystripe-account',
        component: () => import('@/views/pages/home/paymentmethods.vue'),

    },
    {
        path: '/verifymailchimp',
        name: 'mailchimp-account',
        component: () => import('@/views/pages/home/projectdetail.vue'),

    },
    {
        path: '/verifygetresponse',
        name: 'getresponse-account',
        component: () => import('@/views/pages/home/projectdetail.vue'),

    },
    {
        path: '/verifysendlane',
        name: 'sendlane-account',
        component: () => import('@/views/pages/home/projectdetail.vue'),

    },
    {
        path: '/verifyaweber',
        name: 'aweber-account',
        component: () => import('@/views/pages/home/projectdetail.vue'),

    },
    {
        path: '/editproject/:id',
        name: 'project-edit',
        component: () => import('@/views/pages/home/editproject.vue'),
        meta: {
            navActiveLink: 'projects',
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    //{
    //    path: '/pages/authentication/login-v1',
    //    name: 'auth-login-v1',
    //    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    //    meta: {
    //        layout: 'full',
    //    },
    //},
    //{
    //    path: '/pages/authentication/login-v2',
    //    name: 'auth-login-v2',
    //    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    //    meta: {
    //        layout: 'full',
    //    },
    //},
    {
        path: '/register',
        name: 'auth-register',
        component: () => import('@/views/pages/authentication/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/pages/authentication/register-v1',
        name: 'auth-register-v1',
        component: () => import('@/views/pages/authentication/Register-v1.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/pages/authentication/register-v2',
        name: 'auth-register-v2',
        component: () => import('@/views/pages/authentication/Register-v2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    
    {
        path: '/reset-password',
        name: 'auth-reset-password',
        component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    
    {
        path: '/pages/miscellaneous/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/pages/miscellaneous/under-maintenance',
        name: 'misc-under-maintenance',
        component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/pages/miscellaneous/error',
        name: 'misc-error',
        component: () => import('@/views/pages/miscellaneous/Error.vue'),
        meta: {
            layout: 'full',
        },
    },
   
    {
        path: '/profile',
        name: 'pages-profile',
        component: () => import('@/views/pages/profile/Profile.vue'),
        //meta: {
        //    pageTitle: 'Profile',
        //    breadcrumb: [
        //        {
        //            text: 'Pages',
        //        },
        //        {
        //            text: 'Profile',
        //            active: true,
        //        },
        //    ],
        //},
    },

    
    {
        path:'/projectcontacts/:id',
        name:'projectcontacts',
        component: () => import('@/views/pages/home/projectcontact.vue'),

    }
]
