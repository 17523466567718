// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
    app: {
        appName: 'Landingly',
        apiURL: 'https://api.landingly.com',  // https://api.landingly.com  ,https://localhost:44391
        ProfileImageMaxSize:800,
        RefreshTokenExpiryTime:1,//86400,        
        //time in seconds for 24hrs It would be eqaul to This Time + Access Token Expiry Time.

        /*
        https://api.landingly.com
        https://landingly.com
        https://localhost:44391
        https://localhost:44390
        */

        UserDomainURL: 'https://landingly.com',  // https://landingly.com ,https://localhost:44390
        UserDomainFilesDir: 'UserDomainFiles',
        UserTemplateFilesDir: 'UserTemplateFiles',
        appLogoImage: require('@/assets/images/logo/logo.svg'),
        appDarkLogoImage: require('@/assets/images/logo/logodark.svg'),
        AutoLoginKey:'62c67646cd684867a80da3e4ba7b1527',

        // Auto Responders
        GetResponse: 1,
        SendLane: 2,
        MailChimp: 3,
        AWeber: 4,

        encryptionKey:'e9c33914-0ad4-4926-ab90-bac61713f931'

    },
    layout: {
        isRTL: false,
        skin: 'dark', // light, dark, bordered, semi-dark
        routerTransition: 'slide-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
        type: 'vertical', // vertical, horizontal
        contentWidth: 'full', // full, boxed
        menu: {
            hidden: false,
            isCollapsed: false,
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: 'floating', // static , sticky , floating, hidden
            backgroundColor: '', // BS color options [primary, success, etc]
        },
        footer: {
            type: 'static', // static, sticky, hidden
        },
        customizer: false,
        enableScrollToTop: true,
    },
}
