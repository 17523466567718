import Vue from 'vue'
import jwtDefaultConfig from './jwtDefaultConfig'
import axios from 'axios'
import qs from "qs"
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import router from '@/router'



export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false


    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                // Get token from localStorage
                const accessToken = this.getToken()

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response,
            error => {
                // const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config

                // if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken().then(r => {
                            this.isAlreadyFetchingAccessToken = false

                            // Update accessToken in localStorage
                            this.setToken(r.data.accessToken)
                            this.setRefreshToken(r.data.refreshToken)

                            this.onAccessTokenFetched(r.data.accessToken)
                        })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(accessToken => {
                            // Make sure to assign accessToken according to your response.
                            // Check: https://pixinvent.ticksy.com/ticket/2413870
                            // Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(this.axiosIns(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                }
                return Promise.reject(error)
            },
        )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    async getToken() {
        if (this.getTokenValidity()) {
            return Vue.CryptoJS.AES.decrypt(localStorage.getItem(this.jwtConfig.storageTokenKeyName), $themeConfig.app.encryptionKey).toString(Vue.CryptoJS.enc.Utf8);
        }
        else {
            var curr = new Date();
            curr.setSeconds(curr.getSeconds() + 60);
            if (curr > new Date(this.getRefreshTokenExpiry())) {

                this.logout();

            } else {
                var token = await this.refreshAccessToken();
                if (token == null) {
                    this.logout();
                }
                return token;
            }

        }
    }
    refreshAccessToken = async () => {
        var axios = require('axios');
        var qs = require('qs');
        console.log("Refresh Token:: " + this.getRefreshToken());
        console.log("Access Token:: " + Vue.CryptoJS.AES.decrypt(localStorage.getItem(this.jwtConfig.storageTokenKeyName), $themeConfig.app.encryptionKey).toString(Vue.CryptoJS.enc.Utf8));
        var data = qs.stringify({
            'refresh_token': this.getRefreshToken(),
            'grant_type': 'refresh_token'
        });
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        var WebAPIURL = $themeConfig.app.apiURL;

        return await axios.post('' + WebAPIURL + '/token', data, {
            headers: headers
        })
            .then((response) => {
                var expirytime = $themeConfig.app.RefreshTokenExpiryTime;
                //console.log("refreshAccessToken Access Toke:: " + response.data.access_token);
                this.setToken(response.data.access_token);
                this.setRefreshToken(response.data.refresh_token);
                var currDate = new Date();
                var expiryDate = new Date(currDate.setSeconds(currDate.getSeconds() + response.data.expires_in));
                this.setTokenExpiry(expiryDate);
                var refreshTokenExpiryTime = new Date(currDate.setSeconds(currDate.getSeconds() + expirytime));
                useJwt.setRefreshTokenExpiry(refreshTokenExpiryTime);
                localStorage.setItem('User_Name', JSON.stringify(response.data.fullName));
                this.setUserEmail(JSON.stringify(response.data.email));
                return response.data.access_token;
            }).catch((error) => {
                console.log("refreshAccessToken Error:: " + error);
                return null;
            });

    }

    getRefreshToken() {

        return Vue.CryptoJS.AES.decrypt(localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName), $themeConfig.app.encryptionKey).toString(Vue.CryptoJS.enc.Utf8);
    }

    setUserEmail(value) {
        localStorage.setItem('email', value)
    }

    getuserEmail() {
        return JSON.parse(localStorage.getItem('email'))
    }

    getuserName() {
        return JSON.parse(localStorage.getItem('User_Name'))
    }

    setSubDomain(value) {
        localStorage.setItem(this.jwtConfig.subDomain, value)
    }

    getSubDomain() {
        if (this.getTokenValidity()) {
            
            var subDomain = localStorage.getItem(this.jwtConfig.subDomain);
            if (subDomain == null) {
               // this.logout();
               // router.go('/login');
               return null;
            }
            else {
                return subDomain;
            }
        } else {
            var curr = new Date();
            curr.setSeconds(curr.getSeconds() + 60);
            if (curr > new Date(this.getRefreshTokenExpiry())) {
                this.logout();
            } else {
                
                return "";
            }
        }
        
        //var userId = this.userIdExists();
        
        
    }

    async getToken() {
        if (this.getTokenValidity()) {
            return Vue.CryptoJS.AES.decrypt(localStorage.getItem(this.jwtConfig.storageTokenKeyName), $themeConfig.app.encryptionKey).toString(Vue.CryptoJS.enc.Utf8);
        }
        else {
            var curr = new Date();
            curr.setSeconds(curr.getSeconds() + 60);
            if (curr > new Date(this.getRefreshTokenExpiry())) {

                this.logout();

            } else {
                var token = await this.refreshAccessToken();
                if (token == null) {
                    this.logout();
                }
                return token;
            }

        }
    }

    checkTokenValidity() {
        var CurrDate = new Date();
        CurrDate.setSeconds(CurrDate.getSeconds() + 60);
        var expiryDate = new Date(this.getTokenExpiry());
        if (expiryDate > CurrDate) {
            return true;
        }
        else {
            return false;
        }
    }

    setUserId(value) {
        const encryptedText = Vue.CryptoJS.AES.encrypt(value, $themeConfig.app.encryptionKey).toString();
        localStorage.setItem(this.jwtConfig.userId, encryptedText)
    }

    userIdExists() {
        var userId = localStorage.getItem(this.jwtConfig.userId);
        if (userId == null) {
            return null;
        }
        else {
            return userId;
        }
       
    }

    getUserId() {
        return Vue.CryptoJS.AES.decrypt(localStorage.getItem(this.jwtConfig.userId), $themeConfig.app.encryptionKey).toString(Vue.CryptoJS.enc.Utf8);
    }

    setUserRole(value) {
        const encryptedText = Vue.CryptoJS.AES.encrypt(value, $themeConfig.app.encryptionKey).toString();
        localStorage.setItem(this.jwtConfig.userRole, encryptedText)
    }

    getUserRole() {
        return Vue.CryptoJS.AES.decrypt(localStorage.getItem(this.jwtConfig.userRole), $themeConfig.app.encryptionKey).toString(Vue.CryptoJS.enc.Utf8);
    }

    setToken(value) {
        const encryptedText = Vue.CryptoJS.AES.encrypt(value, $themeConfig.app.encryptionKey).toString();
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, encryptedText)
    }

    setTokenExpiry(value) {
        localStorage.setItem(this.jwtConfig.storageTokenExpiryKeyName, value)
    }
    setRefreshTokenExpiry(value) {
        localStorage.setItem(this.jwtConfig.refreshTokenExpirytime, value)
    }
    getRefreshTokenExpiry() {
        return localStorage.getItem(this.jwtConfig.refreshTokenExpirytime)
    }

    getTokenExpiry() {
        return localStorage.getItem(this.jwtConfig.storageTokenExpiryKeyName)
    }

    getTokenValidity() {
        var CurrDate = new Date();
        CurrDate.setSeconds(CurrDate.getSeconds() + 60);
        var expiryDate = new Date(this.getTokenExpiry());
        if (expiryDate > CurrDate) {
            return true;
        }
        else {
            return false;
        }
    }



    setRefreshToken(value) {
        const encryptedText = Vue.CryptoJS.AES.encrypt(value, $themeConfig.app.encryptionKey).toString();
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, encryptedText)
    }

    login(args) {
        var data = {
            'UserName': args.email,
            'Password': args.password,
            'IsAllow': args.IsAllow,
            'grant_type': 'password',
        };
        var WebAPIURL = $themeConfig.app.apiURL;
       // console.log(WebAPIURL + '/token');
        return axios.post('' + WebAPIURL + '/token', qs.stringify(data))
    }

    forgotpass(args) {
        var data = {
            'Email': args.email,
        };
        var WebAPIURL = $themeConfig.app.apiURL;
        return axios.post('' + WebAPIURL + '/api/User/ForgotPassword', qs.stringify(data))
    }

    reseetpass(args) {
        var data = {

            'Password': args.password,
            'ConfirmPassword': args.confirmpassword,
            'UserId': args.userid,
            'Code': args.code,
        };
        var WebAPIURL = $themeConfig.app.apiURL;
        return axios.post('' + WebAPIURL + '/api/User/ResetPassword', qs.stringify(data))
    }

    register(args) {
        var data = qs.stringify({
            'FirstName': args.firstname,
            'LastName': args.lastname,
            'Email': args.email,
            'Password': args.password,
            'ConfirmPassword': args.confirmpassword,
            'CountryId': args.countryid,
            'SubDomain': args.subdomain
        });
        var WebAPIURL = $themeConfig.app.apiURL;
        return axios.post('' + WebAPIURL + '/Register', data)
    }

    refreshToken() {
        var WebAPIURL = $themeConfig.app.apiURL;
        var data = {
            'refresh_token': this.getRefreshToken(),
            'grant_type': 'refresh_token',
        };
        return axios.post('' + WebAPIURL + '/token', qs.stringify(data))
    }
    logout() {
        const ability = [
            {
                action: 'manage',
                subject: 'all',
            },
        ]


        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageTokenExpiryKeyName);
        localStorage.removeItem(useJwt.jwtConfig.refreshTokenExpirytime);
        localStorage.removeItem(useJwt.jwtConfig.userRole);
        localStorage.removeItem(useJwt.jwtConfig.subDomain);
        localStorage.removeItem(useJwt.jwtConfig.userId);

        // Remove userData from localStorage
        localStorage.removeItem('User_Name');
        localStorage.removeItem('email');
        localStorage.removeItem('videoUrl');

        var IsEmpirelyUser = localStorage.getItem('IsEmpirelyUser');
        localStorage.removeItem('IsEmpirelyUser');
        if (IsEmpirelyUser !== 'true') {
            router.go('/login')
        }
        else {
            window.open("https://app.empirely.ai", '_self');
        }
        // Reset ability
        //this.$ability.update(initialAbility)
        // Redirect to login page
    }

}
