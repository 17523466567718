export default {
  // Endpoints
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',


  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'keUZy15fSSkr3OQ9QE26',
    storageRefreshTokenKeyName: 'a2zK0j8EK0Ds5quqp51G',
    storageTokenExpiryKeyName: 'Rca57xPIEUXKdN3rcWtL',
    storageUserEmail: 'Pzmfaoktz1nolOtw0k4j',
    refreshTokenExpirytime:'7X7Wgy8Banh3qGv0cJAx',
    userRole:'rQRTWVCpwOxEdDNIas7N',
    subDomain: 'H0heVRl2uMuQ4WHex6HX',
    userId: 'JjGCfMQ2c1wbGvuQe8Pl',

    /* Random Strings
     sJ9GelbDAnieD7vkGrfX
Pzmfaoktz1nolOtw0k4j
DmHiEXMau1KMO0XKkI8p
LwekLkGBaK42c6VlpeKk
6OQfFaC5yLl7koV4n9ov
qWFC1Z4v3EVF9VleuPWL
H0heVRl2uMuQ4WHex6HX
JjGCfMQ2c1wbGvuQe8Pl



7X7Wgy8Banh3qGv0cJAx
UKUUBu2scMhmblnYOW1P
hXZnF0WjZMN6zhCYqYjG
joGic2y4yXx0iSkVZXM6
kYj2ifrSepglTnMJaWbQ
1lPanGOrT5VjgMPl4cvz
i4Z2NbJFe5OO4J2IqnhL
W4t68Zo4XsGObjNTEj9O
jSDz8Az61ShyI9myD2RP
ee9OQceUh8yl0dFhjguV
bdEy9JcVdQRY0JD864KU
     */
}
