import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import useJwt from '@/auth/jwt/useJwt'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/', redirect: { name: 'dashboard' } },
        ...apps,
        ...dashboard,
        ...pages,
        ...chartsMaps,
        ...formsTable,
        ...uiElements,
        ...others,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    if (window.location.protocol === 'http:') {
        if (window.location.hostname != 'localhost' && window.location.hostname != '127.0.0.1') {
            const httpsURL = `https://${window.location.host}${to.path}`
            console.log("httpsURL", httpsURL);
            window.location.href = httpsURL
        }
        else {
            console.log("window.location.hostname", window.location.hostname);
        }
    }

    // fetch auto login attempt
    var query = to.query;
    if (query.code != undefined && query.loginemail != undefined) {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageTokenExpiryKeyName);
        localStorage.removeItem(useJwt.jwtConfig.refreshTokenExpirytime);
        localStorage.removeItem(useJwt.jwtConfig.userRole);
        localStorage.removeItem(useJwt.jwtConfig.subDomain);
        localStorage.removeItem(useJwt.jwtConfig.userId);

        // Remove userData from localStorage
        localStorage.removeItem('User_Name');
        localStorage.removeItem('email');
        localStorage.removeItem('videoUrl');

    }

    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        console.log("isLoggedIn : " + isLoggedIn);
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'auth-login' })
        
        // If logged in => not authorized
        return next({ name: 'misc-not-authorized' })

    }


    //Redirect if logged in -- previous code
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        var userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
    
})

export default router
